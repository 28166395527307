import React, { useState } from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import TabPanel from 'components/common/subcomponents/TabPanel';
import { AccordionProvider } from './accordion/useAccordion';
import { AccordionItem } from './accordion/AccordionItem';
import { theme } from 'tailwind.config';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
`;

const SupportFAQ = ({ module }) => {
  const { fields } = module;

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '75%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Tabs value={tabIndex} onChange={(e, nv) => setTabIndex(nv)} aria-label='Support Tab'>
            <Tab label='Product Support' id='product-support' aria-controls='product-support-tabpanel' />
            <Tab label='Technical Support' id='technical-support' aria-controls='technical-support-tabpanel' />
          </Tabs>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(217, 217, 217, 0.3)',
          }}
        >
          <TabPanel value={tabIndex} name='Support' index={0} style={{ width: '79%' }}>
            <TabPanel value={tabIndex} index={0} tabName='product-support'>
              <Typography sx={{ mb: '30px' }}>{fields.productSupportDescription}</Typography>
              <Frame>
                <AccordianContainer>
                  <AccordionProvider>
                    {fields.productSupportFAQ
                      ?.sort((a, b) => {
                        if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                        if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                        return 0;
                      })
                      .map(({ contentID, fields: { answer, question } }, index) => (
                        <AccordionItem key={contentID} title={question} index={index}>
                          <Answer
                            className='prose max-w-full mx-auto'
                            style={{ margin: '-20px 0 -5px 0' }}
                            dangerouslySetInnerHTML={renderHTML(answer)}
                          />
                        </AccordionItem>
                      ))}
                  </AccordionProvider>
                </AccordianContainer>
                <HelpFormContainer>
                  <FormHeader
                    iconUrl={fields.productSupportFormIcon?.url}
                    iconLabel={fields.productSupportFormIcon?.label}
                    title={fields.productSupportFormTitle}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0' }}>
                    <a href='contact-us' rel='noopener noreferrer'>
                      <KitButton style={{ width: '130px' }} size='small' color='success' round variant='outlined'>
                        CONTACT US
                      </KitButton>
                    </a>
                  </div>
                </HelpFormContainer>
              </Frame>
            </TabPanel>
          </TabPanel>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(217, 217, 217, 0.3)',
          }}
        >
          <TabPanel value={tabIndex} name='Technical' index={1} style={{ width: '79%' }}>
            <TabPanel value={tabIndex} index={1} tabName='technical-support'>
              <Typography sx={{ mb: '30px' }}>{fields.technicalSupportDescription}</Typography>
              <Frame>
                <AccordianContainer>
                  <AccordionProvider>
                    {fields.technicalSupportFAQ
                      ?.sort((a, b) => {
                        if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                        if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                        return 0;
                      })
                      .map(({ contentID, fields: { answer, question } }, index) => (
                        <AccordionItem key={contentID} title={question} index={index}>
                          <Answer
                            className='prose max-w-full mx-auto'
                            style={{ margin: '-20px 0 -5px 0' }}
                            dangerouslySetInnerHTML={renderHTML(answer)}
                          />
                        </AccordionItem>
                      ))}
                  </AccordionProvider>
                </AccordianContainer>
                <HelpFormContainer>
                  <FormHeader
                    iconUrl={fields.technicalSupportFormIcon?.url}
                    iconLabel={fields.technicalSupportFormIcon?.label}
                    title={fields.technicalSupportFormTitle}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0' }}>
                    <a href='contact-us' rel='noopener noreferrer'>
                      <KitButton style={{ width: '130px' }} size='small' color='success' round variant='outlined'>
                        CONTACT US
                      </KitButton>
                    </a>
                  </div>
                </HelpFormContainer>
              </Frame>
            </TabPanel>
          </TabPanel>
        </div>
      </div>
    </Box>
  );
};

export default SupportFAQ;

const FormHeader = (props) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#fff',
          justifyContent: 'center',
          mt: '10px',
          gap: '25px',
          fontSize: '20px',
          lineHeight: '28px',
          fontWeight: 'bold',
          padding: '1rem 1.5rem',
        }}
      >
        <img src={props.iconUrl} alt={props.iconlabel} height='55px' width='55px' />
        <Typography fontSize='28px' fontWeight={600} width='65%'>
          {props.title}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: '1px dashed #000', width: '90%', margin: '5px auto' }}>&nbsp;</Box>
    </>
  );
};

const Frame = styled('Box')(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const AccordianContainer = styled('Box')(({ theme }) => ({
  padding: '0 10px',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HelpFormContainer = styled('Box')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Answer = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: '20px 10px',
  padding: '0 20px',
}));
