import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PleaseWait from 'components/common/PleaseWait';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { states } from 'services/stringUtils';
import { OTHER_DISTRICT, OTHER_SCHOOL } from 'services/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const SchoolSelector = (props) => {
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [districtDisabled, setDistrictDisabled] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [schoolState, setSchoolState] = useState({
    state: null,
    district: null,
    school: null,
    other_city: '',
    other_agency: '',
  });

  useEffect(() => {
    const init = async () => {
      setErrorMessage(null);

      try {
        if (props.state?.length > 0 && props.district_id?.length > 0) {
          let state;
          let district = null;
          let school = null;
          let sList = [];
          state = states.find((s) => s.value === props.state);
          if (state) {
            await fetchDistrictsForState(state, true);
          }
          district = await fetchDistrict(props.district_id, true);
          sList = await fetchSchoolsForDistrict(district, true);
          if (props.school_id && props.school_name) {
            school = { label: props.school_name, value: sList?.find((s) => s?.value?.id === props.school_id)?.value };
          }

          setSchoolState({ ...schoolState, state, district, school, other_city: props.other_city, other_agency: props.other_agency });
          props.onDataChange({ state, district, school, other_city: props.other_city, other_agency: props.other_agency });
        } else {
          let state = null;
          let district = null;
          if (props.state) {
            state = states.find((s) => s.value === props.state);
          }
          if (state) {
            await fetchDistrictsForState(state, true);
          }

          setSchoolState({ ...schoolState, state, district, school: null, other_city: props.other_city, other_agency: props.other_agency });
          props.onDataChange({ state, district, other_city: props.other_city, other_agency: props.other_agency });
        }
        setInitComplete(true);
      } catch (error) {
        setInitComplete(true);
        setErrorMessage(defaultErrorMessage);
        logClientException(error);
      }
    };

    init();
  }, [props.state, props.district_id, props.school_id, props.school_name, props.other_city, props.other_agency]);

  const fetchDistrict = async (district_id, skipLoading = false) => {
    try {
      if (district_id === OTHER_DISTRICT.id) {
        return { label: OTHER_DISTRICT.name, value: OTHER_DISTRICT };
      }
      if (!skipLoading) {
        setFetching(true);
      }
      setSchoolList([]);
      let res = await callApi(`/api/db/district-list?did=${district_id}`);
      if (res.results[0]) {
        return { label: res.results[0].name, value: res.results[0] };
      }
      setFetching(false);
      setSchoolList([]);
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchDistrictsForState = async (state, skipLoading = false) => {
    try {
      if (!skipLoading) {
        setFetching(true);
      }
      let res = await callApi(`/api/db/district-list?state=${state.value}`);
      let dList = res.results.map((r) => ({ label: r.name, value: r }));
      dList.push({ label: OTHER_DISTRICT.name, value: OTHER_DISTRICT });
      setDistrictList(dList);
      setSchoolList([]);
      setFetching(false);
      return dList;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const fetchSchoolsForDistrict = async (district, skipLoading = false) => {
    try {
      if (district.value.id === OTHER_DISTRICT.id) {
        setSchoolList([{ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL }]);
        return;
      }
      if (!skipLoading) {
        setFetching(true);
      }
      setSchoolList([]);
      let res = await callApi(`/api/db/school-list?district-id=${district.value.id}`);
      setFetching(false);
      const tempSchools = res.results.map((r) => ({ label: r.name, value: r }));
      tempSchools.push({ label: OTHER_SCHOOL.name, value: OTHER_SCHOOL });
      setSchoolList(tempSchools);
      setFetching(false);
      return tempSchools;
    } catch (error) {
      setFetching(false);
      setErrorMessage(defaultErrorMessage);
      logClientException(error);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'state') {
      setSchoolState({ ...schoolState, state: value, district: null, school: null });
      props.onDataChange({ ...schoolState, state: value, district: null, school: null });
    } else if (field === 'district') {
      if (value?.value?.id === OTHER_DISTRICT.id) {
        setSchoolState({ ...schoolState, district: value, school: { label: OTHER_SCHOOL.name, value: OTHER_SCHOOL } });
        props.onDataChange({ ...schoolState, district: value, school: { label: OTHER_SCHOOL.name, value: OTHER_SCHOOL } });
      } else {
        setSchoolState({ ...schoolState, district: value, school: null });
        props.onDataChange({ ...schoolState, district: value, school: null });
      }
    } else {
      setSchoolState({ ...schoolState, [field]: value });
      props.onDataChange({ ...schoolState, [field]: value });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (!initComplete) {
    return (
      <Box sx={{ marginTop: '10px' }}>
        {[1, 2, 3].map((i) => {
          return <Skeleton key={i} variant='rounded' height={55} sx={{ marginBottom: '5px' }} />;
        })}
      </Box>
    );
  }

  return (
    <>
      <PleaseWait isLoading={fetching} />
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Autocomplete
        sx={{ marginTop: '7px' }}
        disabled={districtDisabled}
        disablePortal
        options={states}
        isOptionEqualToValue={(o, v) => o.value === v?.value}
        fullWidth
        value={schoolState.state}
        onChange={async (e, v) => {
          if (v) {
            await fetchDistrictsForState(v);
          }
          handleInputChange('state', v);
        }}
        renderInput={(params) => (
          <TextField
            required
            error={props.stateError}
            {...params}
            label='State'
            variant='standard'
            margin='dense'
            InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
          />
        )}
      />

      <Autocomplete
        sx={{ marginTop: '7px' }}
        disablePortal
        options={districtList}
        fullWidth
        isOptionEqualToValue={(o, v) => o.value.id === v?.value?.id}
        disabled={!schoolState.state}
        value={schoolState.district}
        onChange={async (e, v) => {
          if (v) {
            await fetchSchoolsForDistrict(v);
          }
          handleInputChange('district', v);
        }}
        renderInput={(params) => (
          <TextField
            disabled={!schoolState.state}
            required
            error={props.districtError}
            {...params}
            label='School district'
            variant='standard'
            margin='dense'
            InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
          />
        )}
      />

      {schoolState.district?.value?.id !== OTHER_DISTRICT.id && (
        <Autocomplete
          sx={{ marginTop: '7px' }}
          disablePortal
          options={schoolList}
          isOptionEqualToValue={(o, v) => o.value === v?.value}
          fullWidth
          disabled={!schoolState.district || !schoolList || schoolList.length == 0}
          value={schoolState.school}
          onChange={(e, v) => handleInputChange('school', v)}
          renderInput={(params) => (
            <TextField
              required={props.requireSchool}
              error={props.schoolError}
              {...params}
              label='School'
              variant='standard'
              margin='dense'
              InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
            />
          )}
        />
      )}

      {schoolState.district?.value?.id === OTHER_DISTRICT.id && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: props.compactCityAndAgency ? 'row' : 'column',
            justifyContent: props.compactCityAndAgency ? 'space-between' : 'center',
            flexWrap: 'wrap',
          }}
        >
          <TextField
            sx={{ marginBottom: '5px', width: props.compactCityAndAgency ? '47.5%' : '100%' }}
            value={schoolState.other_city}
            error={props.other_cityError}
            required={props.requireCity}
            margin='dense'
            id='City'
            label='City'
            type='text'
            fullWidth={!props.compactCityAndAgency}
            variant='standard'
            onChange={(e) => handleInputChange('other_city', e.target.value)}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
          />
          <TextField
            sx={{ width: props.compactCityAndAgency ? '47.5%' : '100%' }}
            value={schoolState.other_agency}
            error={props.other_agencyError}
            required
            margin='dense'
            id='Agency'
            label='Agency'
            type='text'
            fullWidth={!props.compactCityAndAgency}
            variant='standard'
            onChange={(e) => handleInputChange('other_agency', e.target.value)}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true, style: { fontSize: props.labelSize } }}
          />
        </Box>
      )}
    </>
  );
};

SchoolSelector.defaultProps = {
  requireSchool: true,
  compactCityAndAgency: false,
};

SchoolSelector.propTypes = {
  labelSize: PropTypes.string,
  state: PropTypes.string,
  district_id: PropTypes.string,
  school_id: PropTypes.string,
  school_name: PropTypes.string,
  other_agency: PropTypes.string,
  other_city: PropTypes.string,
  requireCity: PropTypes.bool,
  stateError: PropTypes.bool,
  districtError: PropTypes.bool,
  schoolError: PropTypes.bool,
  other_cityError: PropTypes.bool,
  other_agencyError: PropTypes.bool,
  requireSchool: PropTypes.bool,
  onDataChange: PropTypes.func.isRequired,
  compactCityAndAgency: PropTypes.bool,
};

export default SchoolSelector;
