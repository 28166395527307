import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

export const BannerContainer = styled('div')(({ theme, imgUrl }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  height: '250px',
  '> img': {
    maxHeight: '260px',
  },
  '.lessonText': {
    fontSize: '27px',
    color: theme.palette.text.light,
  },
  '.title': {
    fontSize: '50px',
    color: theme.palette.primary.dark,
    fontWeight: 700,
    lineHeight: '54px',
    textTransform: 'uppercase',
  },
  [theme.breakpoints.down('md')]: {
    height: '125px',
    '.lessonText': {
      fontSize: '19px',
    },
    '.title': {
      fontSize: '29px',
      lineHeight: '34px',
    },
    '> img': {
      maxHeight: '125px',
    },
  },
}));

export const HeadingAndList = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '30% auto',
  [theme.breakpoints.down('md')]: {
    marginTop: '2rem',
    gridTemplateColumns: '1fr',
    //gridTemplateRows: '1fr',
    '> div.title': {
      fontWeight: 700,
    },
    '> div': {
      gridColumn: 'auto',
    },
  },
}));

export const SubHeadingContainer = styled('div')(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
  textTransform: 'uppercase',
  fontSize: '14px',
  lineHeight: '28px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
    marginLeft: '.5rem',
    marginRight: '.5rem',
  },
}));

export const SubHeadingSeperator = styled('hr')(({ theme }) => ({
  width: 'calc(100% + 200px)',
  borderStyle: 'dashed',
  borderTopWidth: '.5px',
  position: 'absolute',
  left: '-200px',
  height: '1rem',
  borderColor: theme.palette.grey.A400,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    left: 0,
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const SectionSeperator = styled('div')(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.grey.A400}`,
  gridColumn: 'span 2',
  margin: '1.25rem 0',
  [theme.breakpoints.down('md')]: {
    border: 'none',
  },
}));

export const SuggestedMaterials = styled('div')(({ theme }) => ({
  'a': {
    textDecoration: 'underline',
  },
  'ul': {
    listStyleType: 'disc',
    marginLeft: '1.5rem',
    'ul': {
      listStyleType: 'circle',
    },
  },
  'ol': {
    listStyleType: 'decimal',
    'ol': {
      listStyleType: 'lower-roman',
    },
  },
}));

export const BulletSection = styled('div')(({ theme, noMargin }) => ({
  'ul': {
    listStyleType: 'disc',
    marginLeft: `${noMargin ? 0 : '1.5rem'}`,
    'ul': {
      listStyleType: 'circle',
    },
  },
  'ol': {
    listStyleType: 'decimal',
    marginLeft: `${noMargin ? 0 : '1.5rem'}`,
    'ol': {
      listStyleType: 'lower-roman',
    },
  },
}));

export const TitledHtmlListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1.5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '> img': {
      transform: 'rotate(90deg)',
      maxHeight: '70px',
    },
  },
}));

export const TitledHtmlListArea = ({ title, htmlList }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.common.white, width: { xs: '325px', md: '410px' } }}>
        <div style={{ borderBottom: `1px dashed ${theme.palette.common.white}`, display: 'flex', justifyContent: 'center', padding: '1rem' }}>
          {title}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '1.5rem' }}>
          <BulletSection noMargin={true} dangerouslySetInnerHTML={renderHTML(htmlList)}></BulletSection>
        </div>
      </Box>
    </div>
  );
};

export const FullWidthHR = styled('hr')(({ theme }) => ({
  width: 'calc(100% + 200px)',
  borderTopWidth: '.5px',
  position: 'absolute',
  left: '-200px',
  borderColor: theme.palette.grey.A400,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    borderTopWidth: '.5px',
    position: 'absolute',
    left: 0,
  },
}));

export const Breadcrumb = styled('span')(({ theme, color }) => ({
  textDecoration: 'underline',
  color: color === 'default' ? theme.palette.primary.dark : theme.palette.text[color],
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
