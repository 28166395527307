import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Box, IconButton } from '@mui/material';

const BackToTopButton = ({ scrollTop, scrollTrigger }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(window.scrollY > scrollTrigger);
  };

  const scrollToTop = () => {
    setIsVisible(false);
    window.scrollTo({
      top: scrollTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <Box sx={{ position: 'fixed', right: '2rem', bottom: '2rem' }}>
      {isVisible && (
        <IconButton onClick={scrollToTop} aria-label='Back to top' title='Back to top'>
          <ArrowCircleUpIcon sx={{ fontSize: '5rem' }} color='primary' />
        </IconButton>
      )}
    </Box>
  );
};

BackToTopButton.defaultProps = {
  scrollTop: 0,
  scrollTrigger: 500,
};

BackToTopButton.propTypes = {
  scrollTop: PropTypes.number,
  scrollTrigger: PropTypes.number,
};

export default BackToTopButton;
