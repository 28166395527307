import styled from '@emotion/styled';
import Link from 'next/link';

const DetailNav = ({ item }) => {
  const getSectionLinks = (title, actions, depth) => {
    if (!title || title.length === 0 || !actions || actions.length === 0) {
      return null;
    }
    return (
      <li>
        <Link href={`#title-${depth}`}>{title}</Link>
        <ul>
          {actions.map((a, i) => {
            return (
              <li key={i}>
                <Link href={`#action-${depth}-${i}`}>{a.fields.actionName}</Link>
              </li>
            );
          })}
        </ul>
      </li>
    );
  };

  return (
    <MainStyledUl>
      {getSectionLinks(item.secondaryActionTitle, item.secondaryActions?.items, 2)}
      {getSectionLinks(item.thirdActionTitle, item.tertiaryActions?.items, 3)}
      {getSectionLinks(item.fourthActionTitle, item.quaternaryActions?.items, 4)}
      {getSectionLinks(item.fifthActionTitle, item.quinaryActions?.items, 5)}
    </MainStyledUl>
  );
};

const MainStyledUl = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  color: theme.palette.primary.main,
  marginTop: '1rem',
  '& a': {
    textDecoration: 'underline',
  },
  '& li': {
    padding: 0,
    margin: 0,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      marginLeft: '1rem',
      '& li': {
        padding: 0,
        margin: 0,
      },
    },
  },
}));

export default DetailNav;
