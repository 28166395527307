// package imports
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatTime } from 'services/stringUtils';

// local imports
import { OTHER_SCHOOL } from 'services/constants';
import { displayActivity } from 'services/observationUtils';
import SessionInfoEditDialog from '../SessionInfoEditDialog';

const ObservationHeaderDetail = ({ observation, observationMetrics, doInfoUpdate }) => {
  const theme = useTheme();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const startTime = observation.timer[0]?.timer_start ? formatTime(observation.timer[0].timer_start) : 'N/A';
  const endTime = observation.timer[0]?.timer_end ? formatTime(observation.timer[0].timer_end) : 'N/A';

  return (
    <Box sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: theme.palette.white.main }}>
      <div>
        Observation time: {startTime} - {endTime} ( {observationMetrics.observationMinutes.toFixed(0)} minutes )
      </div>
      <div>{observation.classroom_type}</div>
      <div>
        {observation.teacher} {observation.room_number && observation.room_number.length > 0 ? '(Room ' + observation.room_number + ')' : ''}
      </div>
      <div>{observation.agency_id === OTHER_SCHOOL.id ? observation.district_name : observation.agency_name}</div>
      {observation.grade && <div>{observation.grade}</div>}
      {observation.subject && observation.subject !== 'Other' && <div>{observation.subject}</div>}
      {observation.other_subject && <div>{observation.other_subject}</div>}
      {displayActivity(observation)}
      <div>Observed by: {observation.observer_name ?? 'Unknown'}</div>
      <div>Student Count: {observation.number_of_students}</div>
      <Box sx={{ marginTop: '5px' }}>
        <Button color='white' variant='outlined' size='small' startIcon={<EditIcon />} onClick={() => setShowEditDialog(true)}>
          Edit
        </Button>
      </Box>
      <SessionInfoEditDialog open={showEditDialog} onClose={() => setShowEditDialog(false)} doInfoUpdate={doInfoUpdate} />
    </Box>
  );
};

export default ObservationHeaderDetail;
