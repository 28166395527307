import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Checkbox, FormControlLabel, useMediaQuery, Pagination, IconButton, Drawer, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSession } from 'next-auth/react';
import { logServerException } from 'appinsights/clientAppInsights';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import PageContainer from 'components/common/subcomponents/PageContainer';
import { useRef, useState } from 'react';
import { FilterIcon } from 'components/common/subcomponents/SvgIcons';
import NewsLetterComponent from '../NewsletterComponent';
import { isCustomer } from 'services/subscriptionUtils';
import BehaviorCard from './BehaviorCard';

const PER_PAGE = 14;

const BehaviorChallengesLanding = ({ module: { fields }, customData }) => {
  const theme = useTheme();
  const { data: session, status } = useSession();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const gradeLevels = customData?.gradeLevels?.items ?? [];
  const skillList = customData?.skillList?.items ?? [];
  const details = customData?.details?.items ?? [];
  const [gradeLevelFilter, setGradeLevelFilter] = useState([]);
  const [skillFilter, setSkillFilter] = useState([]);
  const [filteredDetailList, setFilteredDetailList] = useState(details);
  const [page, setPage] = useState(1);
  const [filterOpen, setFilterOpen] = useState(false);
  const scrollRef = useRef(null);

  const clearFilter = () => {
    setGradeLevelFilter([]);
    setSkillFilter([]);
    filterDetails([], []);
    setPage(1);
  };

  const filterDetails = (skills, gradeLevels) => {
    if (skills.length === 0 && gradeLevels.length === 0) {
      setPage(1);
      setFilteredDetailList(details);
      scrollToStart();
      return;
    }
    const temp = details.filter((l) => {
      let hasSkill = false;
      let hasGradeLevel = false;
      if (l?.fields.skills?.filter((s) => skills.indexOf(s.fields.skill) > -1).length > 0) {
        hasSkill = true;
      }
      if (l?.fields.grades?.filter((g) => gradeLevels.indexOf(g.fields.gradeLevel) > -1).length > 0) {
        hasGradeLevel = true;
      }
      return hasSkill || hasGradeLevel;
    });
    setPage(1);
    setFilteredDetailList(temp);
    scrollToStart();
  };

  const updatePage = (p) => {
    setPage(p);
  };

  const getPageOfData = () => {
    const start = PER_PAGE * (page - 1);
    return filteredDetailList.slice(start, start + PER_PAGE);
  };

  const filterChange = (type, e) => {
    if (type === 'skill') {
      const temp = skillFilter.slice();
      if (e.target.checked && temp.indexOf(e.target.value) === -1) {
        temp.push(e.target.value);
      } else if (!e.target.checked) {
        const i = temp.indexOf(e.target.value);
        if (i > -1) {
          temp.splice(i, 1);
        }
      }
      setSkillFilter(temp);
      filterDetails(temp, gradeLevelFilter);
    }
    if (type === 'gradeLevel') {
      const temp = gradeLevelFilter.slice();
      if (e.target.checked && temp.indexOf(e.target.value) === -1) {
        temp.push(e.target.value);
      } else if (!e.target.checked) {
        const i = temp.indexOf(e.target.value);
        if (i > -1) {
          temp.splice(i, 1);
        }
      }
      setGradeLevelFilter(temp);
      filterDetails(skillFilter, temp);
    }
  };

  const getFilterItems = () => {
    return (
      <>
        <Box sx={{ display: isSmallScreen ? 'flex' : 'none', justifyContent: 'flex-end', width: '100%' }}>
          <IconButton onClick={() => setFilterOpen(false)} sx={{ color: theme.palette.text.dark }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <KitButton color='info' onClick={clearFilter} round style={{ marginBottom: '20px' }}>
          Clear All Selection
        </KitButton>
        <FilterHeading>Skills</FilterHeading>
        <div style={{ marginBottom: '.75rem' }}>
          {skillList.map((b) => {
            return (
              <FormControlLabel
                sx={{ width: '100%', fontSize: '13px', fontWeight: 400, lineHeight: '22px', color: theme.palette.text.medium }}
                key={b.fields.skill}
                control={
                  <Checkbox
                    size='small'
                    checked={skillFilter.indexOf(b.fields.skill) > -1}
                    value={b.fields.skill}
                    onChange={(e) => filterChange('skill', e)}
                  />
                }
                label={b.fields?.skill}
              />
            );
          })}
        </div>
        <FilterHeading>Grade Level</FilterHeading>
        <div>
          {gradeLevels.map((l, i) => {
            return (
              <FormControlLabel
                sx={{ width: '100%', fontSize: '13px', fontWeight: 400, lineHeight: '22px', color: theme.palette.text.medium }}
                key={l.fields.gradeLevel}
                control={
                  <Checkbox
                    size='small'
                    checked={gradeLevelFilter.indexOf(l.fields.gradeLevel) > -1}
                    value={l.fields.gradeLevel}
                    onChange={(e) => filterChange('gradeLevel', e)}
                  />
                }
                label={l.fields.gradeLevel}
              />
            );
          })}
        </div>
      </>
    );
  };

  const scrollToStart = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (typeof window !== 'undefined') {
        window.setTimeout(() => {
          scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 250);
      }
    }
  };

  return (
    <PageContainer>
      {(status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
            marginTop: { xs: '100px', sm: '150px' },
          }}
          open={true}
        >
          <div style={{ width: '100%' }}>
            <NewsLetterComponent
              cTAText1={fields.cTAText1}
              cTAText2={fields.cTAText2}
              unsubscribeText={fields.unsubscribeText}
              cTAlink={fields.cTAlink}
              cTALinkColor={fields.cTALinkColor}
              backgroundColor={fields.backgroundColor}
              accentImage={fields.accentImage}
            />
          </div>
        </Backdrop>
      )}

      <Drawer
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        anchor='left'
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box id='filterWrapper' sx={{ width: 250, padding: '.5rem', height: '100%' }} role='presentation'>
          {getFilterItems()}
        </Box>
      </Drawer>
      <Box sx={{ display: isSmallScreen ? 'flex' : 'none' }}>
        <IconButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.scrollTo(0, 0);
              setFilterOpen(true);
              window.setTimeout(() => {
                window.scrollTo(0, 0);
              }, 250);
            }
          }}
        >
          <FilterIcon fillColor={theme.palette.primary.main} height='24' width='24' />
          <span style={{ fontSize: '18px', lineHeight: '23.4px', weight: 400, color: theme.palette.text.dark, marginLeft: '.5rem' }}> Filter</span>
        </IconButton>
      </Box>
      <Box
        ref={scrollRef}
        sx={{
          display: { md: 'grid', xs: 'flex' },
          flexDirection: { xs: 'column', md: 'row' },
          gridTemplateColumns: { xs: 'auto', md: 'auto', lg: 'auto 40% 40%' },
          marginTop: { xs: 0, md: '1.5rem' },
        }}
      >
        <div style={{ display: isSmallScreen ? 'none' : 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>{getFilterItems()}</div>

        <CardColumn isSmallScreen={isSmallScreen}>
          {getPageOfData().length === 0 && <div>Nothing Found. Try to adjust the filters.</div>}
          {getPageOfData().map((l, i) => {
            if (!isSmallScreen && i % 2 === 0) {
              return <BehaviorCard item={l} key={i} />;
            } else if (isSmallScreen) {
              return <BehaviorCard item={l} key={i} />;
            }
          })}
        </CardColumn>
        <CardColumn number={2} isSmallScreen={isSmallScreen} id='planColumn2'>
          {getPageOfData().map((l, i) => {
            if (!isSmallScreen && i % 2 === 1) {
              return <BehaviorCard item={l} key={i} />;
            }
          })}
        </CardColumn>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '1.5rem' }}>
        <Pagination
          color='primary'
          size='large'
          shape='circular'
          count={Math.ceil(filteredDetailList.length / PER_PAGE)}
          page={page}
          onChange={(ev, val) => {
            updatePage(val);
            scrollToStart();
          }}
        />
      </Box>
    </PageContainer>
  );
};
// This is called during server-side rendering (not part of NextJS).
BehaviorChallengesLanding.getCustomInitialProps = async ({ agility, languageCode }) => {
  const api = agility;

  try {
    const skillList = await api.getContentList({
      referenceName: 'skilllist',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
      skip: 0,
      sort: 'fields.textValue',
      direction: api.types.SortDirections.ASC,
    });

    const gradeLevels = await api.getContentList({
      referenceName: 'gradelevels',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
      skip: 0,
      sort: 'fields.textValue',
      direction: api.types.SortDirections.ASC,
    });

    const details = await api.getContentList({
      referenceName: 'behaviorinteractions',
      languageCode,
      take: 250,
      contentLinkDepth: 5,
      expandAllContentLinks: true,
    });

    return {
      skillList,
      gradeLevels,
      details,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

const FilterHeading = styled('div')(({ theme }) => ({
  fontSize: '19px',
  fontWeight: 600,
  lineHeight: '21px',
}));

const CardColumn = styled('div')(({ theme, number, isSmallScreen }) => ({
  display: isSmallScreen && number === 2 ? 'none' : 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.lessonPlanCard': {
    marginTop: 0,
    marginBottom: '1.25rem',
  },
  marginTop: isSmallScreen ? '1rem' : 0,
}));

export default BehaviorChallengesLanding;
