// package imports
import { KitCard } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// local imports
import hex2rgba from 'services/colorUtils/hex2rgba';

const BehaviorCard = (props) => {
  const fields = props.item.fields;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getSkillList = () => {
    let tagList = fields?.skills?.map((b) => {
      return (
        <span style={{ marginRight: '10px' }} key={b.fields?.skill}>
          {b.fields?.skill}
        </span>
      );
    });
    if (isSmallScreen) {
      return tagList;
    }
    if (!isSmallScreen) {
      const textList = fields?.skills.map((r) => r.fields?.skill);

      // determine number of tags which can fit on a line
      let maxTags = textList.length;
      for (let i = 1; i <= textList.length; i++) {
        let temp = textList.slice(0, i).join(', ');
        if (temp.length > 50) {
          maxTags = i;
          i = textList.length + 1;
        } else if (i < textList.length) {
          temp = textList.slice(0, i + 1).join(', ');
          if (temp.length > 50) {
            maxTags = i;
            i = textList.length + 1;
          }
        }
      }

      if (tagList.length <= maxTags) {
        return tagList;
      }

      const tooltipTags = textList.slice(maxTags).join(', ');
      tagList = tagList.slice(0, maxTags);
      tagList.push(
        <Tooltip title={tooltipTags} arrow>
          <MoreHorizIcon />
        </Tooltip>
      );
      return tagList;
    }
  };

  return (
    <>
      <KitCard className='behaviorCard' style={{ width: '95%', borderRadius: '2px' }}>
        <div
          style={{
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
            backgroundColor: hex2rgba(theme.palette.tertiary.main, 0.1),
            height: '80px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip title={fields?.title} arrow>
            <Typography sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '21px', marginLeft: '45px' }}>{fields?.title}</Typography>
          </Tooltip>
        </div>

        <Box sx={{ margin: '15px 30px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ textTransform: 'uppercase', fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: theme.palette.primary.light }}>
              <a href={`/premium-resources/common-behavioral-challenges-recommendations/${fields?.slug}`} title='View'>
                View
              </a>
            </span>
          </Box>
        </Box>
        <hr style={{ borderTop: `1px dashed ${theme.palette.grey['A400']}`, width: '100%' }} />
        <Box sx={{ margin: '15px 30px', display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              fontSize: '13px',
              lineHeight: '22px',
              fontWeight: 400,
              color: theme.palette.text.light,
              textTransform: 'capitalize',
            }}
          >
            {getSkillList()}
          </div>
          <div style={{ fontSize: '13px', lineHeight: '22px', fontWeight: 400, color: theme.palette.text.light, textTransform: 'capitalize' }}>
            {fields?.grades
              .map((g) => g.fields?.gradeLevel)
              .sort()
              .join(', ')}
          </div>
        </Box>
      </KitCard>
    </>
  );
};

export default BehaviorCard;
