import HelpIcon from '@mui/icons-material/Help';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const ActionTitle = ({ id, title }) => {
  const theme = useTheme();

  return (
    <Box
      id={id}
      sx={{
        marginTop: '2rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.obsBlue.light,
        padding: '.75rem',
        borderRadius: '.5rem',
      }}
    >
      <HelpIcon color='primary' sx={{ fontSize: '2.5rem', marginRight: '.5rem' }} />
      <Typography component='span' sx={{ fontSize: '2rem', fontWeight: 600, color: theme.palette.primary.main }}>
        {title}
      </Typography>
      {/* <HelpOutlineIcon color='primary' sx={{ fontSize: '2.5rem' }} /> */}
      <hr />
    </Box>
  );
};

export default ActionTitle;
