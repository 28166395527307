// package imports
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import { useSession } from 'next-auth/react';
import { Backdrop } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from 'next/link';

// local imports
import { logClientException, logServerException } from 'appinsights/clientAppInsights';
import { BannerContainer, Breadcrumb, FullWidthHR, SubHeadingContainer } from '../LessonPlanDetailComponents';
import PleaseWait from '../../common/PleaseWait';
import NewsLetterComponent from '../NewsletterComponent';
import BreadcrumbTrail from '../BreadcrumbTrail';
import { isCustomer } from 'services/subscriptionUtils';
import BehaviorAction from './BehaviorAction';
import ActionTitle from './ActionTitle';
import DetailNav from './DetailNav';
import BackToTopButton from 'components/common/BackToTopButton';

const BehaviorChallengesDetails = (props) => {
  const {
    customData,
    module: { fields },
  } = props;
  const detailPage = customData.detailPage;
  const theme = useTheme();
  const { data: session, status } = useSession();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        //await logResourceAccess('Lesson Plan', lessonPlan?.fields?.title, lessonPlan.fields.slug, 'lesson_plan_detail_access');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        logClientException(error);
      }
    };

    if (status === 'authenticated' && isCustomer(session?.user)) {
      init();
    } else if ((status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && typeof window !== 'undefined') {
      window.onscroll = () => {
        let y = window.scrollY;
        if (y > 500) {
          window.scrollTo(0, 500);
        }
      };
      return () => {
        window.onscroll = null;
      };
    }
  }, [status]);

  const breakCrumbArgs = () => {
    const args = { fields: { 'borderColor': 'A400', hideComponent: 'never', textColor: 'medium', breadcrumbList: [] } };
    args.fields.breadcrumbList.push({ contentID: 0, fields: { breadcrumbUrl: { href: '/', text: 'Home' }, 'name': 'Home', order: 0 } });
    args.fields.breadcrumbList.push({
      contentID: 1,
      fields: {
        breadcrumbUrl: {
          href: '/premium-resources/common-behavioral-challenges-recommendations',
          text: 'Common Behavioral Challenges & Recommentations',
        },
        'name': 'Behavior Interactions',
        order: 1,
      },
    });
    args.fields.breadcrumbList.push({
      contentID: 2,
      fields: {
        breadcrumbUrl: { href: '', text: 'Common Behavioral Challenges & Recommentations Details' },
        'name': 'Common Behavioral Challenges & Recommentations Details',
        order: 2,
      },
    });
    return args;
  };

  if (status === 'loading') {
    return <PleaseWait isLoading={true} />;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          mt: '1rem',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { xs: status === 'unauthenticated' ? '540px' : '375px', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        {(status === 'unauthenticated' || (status === 'authenticated' && !isCustomer(session?.user))) && (
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5), rgba(0, 0, 0, 1))',
              marginTop: { xs: '100px', sm: '150px' },
            }}
            open={true}
          >
            <div style={{ width: '100%' }}>
              <NewsLetterComponent
                cTAText1={fields.cTAText1}
                cTAText2={fields.cTAText2}
                unsubscribeText={fields.unsubscribeText}
                cTAlink={fields.cTAlink}
                cTALinkColor={fields.cTALinkColor}
                backgroundColor={fields.backgroundColor}
                accentImage={fields.accentImage}
              />
            </div>
          </Backdrop>
        )}
        <PleaseWait isLoading={loading} />
        <BackToTopButton scrollTop={700} scrollTrigger={750} />
        <BannerContainer>
          {/* {lessonPlan?.fields?.image?.url && ( 
          <img
            src={`https://content.liftwithboystown.org/Lesson%20Plan%20Images/NTLP-social-skills_greeting-others.svg`}
            alt={'Q & A'}
            style={{ opacity: 0.3, position: 'absolute', top: 0, right: 0 }}
          />
          )} */}
          <div style={{ alignSelf: 'center', position: 'relative' }}>
            <Box
              className='lessonText'
              sx={{ backgroundColor: theme.palette.obsBlue.light, padding: '1rem', borderRadius: '.5rem', fontSize: { xs: '1.5rem', lg: '2.2rem' } }}
            >
              <h1 style={{ color: theme.palette.primary.main, fontWeight: 550 }}>
                <FormatQuoteIcon color='primary' sx={{ fontSize: '3rem' }} />
                {detailPage?.fields?.title}
                <FormatQuoteIcon color='primary' sx={{ fontSize: '3rem' }} />
              </h1>
            </Box>
          </div>
        </BannerContainer>

        <FullWidthHR />
      </Box>
      <BreadcrumbTrail module={breakCrumbArgs()} />

      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          mb: '10rem',
          maxWidth: { xs: status === 'unauthenticated' ? '540px' : '375px', sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <SubHeadingContainer>
          <Breadcrumb>
            <Link href='/premium-resources/common-behavioral-challenges-recommendations' passHref legacyBehavior>
              <a rel='noopener noreferrer' title='back to behaviorial challenges'>
                <ArrowBackIcon />
                Back to Behaviorial Challenges
              </a>
            </Link>
          </Breadcrumb>
        </SubHeadingContainer>

        {detailPage?.fields?.primaryActions?.items?.map((a, i, arr) => {
          return (
            <>
              <BehaviorAction key={i} action={a.fields} />
              {arr.length > 1 && i < arr.length - 1 && <DashedHr />}
            </>
          );
        })}
        <StyledHr />

        <DetailNav item={detailPage.fields} />

        {detailPage?.fields?.secondaryActionTitle?.length > 0 && <ActionTitle id='title-2' title={detailPage?.fields?.secondaryActionTitle} />}
        {detailPage?.fields?.secondaryActions?.items?.map((a, i, arr) => {
          return (
            <>
              <BehaviorAction key={i} id={`action-2-${i}`} action={a.fields} />
              {arr.length > 1 && i < arr.length - 1 && <DashedHr />}
            </>
          );
        })}
        {detailPage?.fields?.secondaryActionTitle?.length > 0 && <StyledHr />}

        {detailPage?.fields?.thirdActionTitle?.length > 0 && <ActionTitle id='title-3' title={detailPage?.fields?.thirdActionTitle} />}
        {detailPage?.fields?.tertiaryActions?.items?.map((a, i, arr) => {
          return (
            <>
              <BehaviorAction key={i} id={`action-3-${i}`} action={a.fields} />
              {arr.length > 1 && i < arr.length - 1 && <DashedHr />}
            </>
          );
        })}
        {detailPage?.fields?.thirdActionTitle?.length > 0 && <StyledHr />}

        {detailPage?.fields?.fourthActionTitle?.length > 0 && <ActionTitle id='title-4' title={detailPage?.fields?.fourthActionTitle} />}
        {detailPage?.fields?.quaternaryActions?.items?.map((a, i, arr) => {
          return (
            <>
              <BehaviorAction key={i} id={`action-4-${i}`} action={a.fields} />
              {arr.length > 1 && i < arr.length - 1 && <DashedHr />}
            </>
          );
        })}
        {detailPage?.fields?.fourthActionTitle?.length > 0 && <StyledHr />}

        {detailPage?.fields?.fifthActionTitle?.length > 0 && <ActionTitle id='title-5' title={detailPage?.fields?.fifthActionTitle} />}
        {detailPage?.fields?.quinaryActions?.items?.map((a, i, arr) => {
          return (
            <>
              <BehaviorAction key={i} id={`action-5-${i}`} action={a.fields} />
              {arr.length > 1 && i < arr.length - 1 && <DashedHr />}
            </>
          );
        })}

        {/* <pre>{JSON.stringify(customData, null, 2)}</pre> */}
      </Box>
    </>
  );
};

// This is called during server-side rendering (not part of NextJS).
BehaviorChallengesDetails.getCustomInitialProps = async ({ agility, languageCode, dynamicPageItem }) => {
  const api = agility;

  const detailPage = dynamicPageItem;

  try {
    if (detailPage?.fields?.primaryActions?.referencename) {
      let raw = await api.getContentList({
        referenceName: detailPage?.fields?.primaryActions.referencename,
        languageCode,
        take: 10,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        detailPage.fields.primaryActions = raw;
      }
    }

    if (detailPage?.fields?.secondaryActions?.referencename) {
      let raw = await api.getContentList({
        referenceName: detailPage?.fields?.secondaryActions.referencename,
        languageCode,
        take: 10,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        detailPage.fields.secondaryActions = raw;
      }
    }

    if (detailPage?.fields?.tertiaryActions?.referencename) {
      let raw = await api.getContentList({
        referenceName: detailPage?.fields?.tertiaryActions.referencename,
        languageCode,
        take: 10,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        detailPage.fields.tertiaryActions = raw;
      }
    }

    if (detailPage?.fields?.quaternaryActions?.referencename) {
      let raw = await api.getContentList({
        referenceName: detailPage?.fields?.quaternaryActions.referencename,
        languageCode,
        take: 10,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        detailPage.fields.quaternaryActions = raw;
      }
    }

    if (detailPage?.fields?.quinaryActions?.referencename) {
      let raw = await api.getContentList({
        referenceName: detailPage?.fields?.quinaryActions.referencename,
        languageCode,
        take: 10,
        contentLinkDepth: 5,
        expandAllContentLinks: true,
      });

      if (raw) {
        detailPage.fields.quinaryActions = raw;
      }
    }

    return {
      detailPage,
    };
  } catch (error) {
    await logServerException(error);
    if (console) console.error(error);
  }
};

export default BehaviorChallengesDetails;

const StyledHr = styled('hr')(({ theme }) => ({
  width: '100%',
  borderTopWidth: '.5px',
  marginTop: '1rem',
  borderColor: theme.palette.grey.A400,
}));

const DashedHr = styled('hr')(({ theme }) => ({
  width: '100%',
  marginTop: '1rem',
  borderTop: `.5px dashed ${theme.palette.grey.A400}`,
}));
