// package imports
import { Box, Typography } from '@mui/material';

// local imports
import { Freeform } from 'components/common/Freeform';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';
import { parseBlocks } from 'services/parseUtils';

const BehaviorAction = ({ id, action }) => {
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Heading6 id={id} sx={{ textDecoration: 'underline', paddingBottom: '.5rem' }}>
        {action.actionName}
      </Heading6>
      <Box sx={{ '& ul,ol,p': { marginBottom: '.75rem' }, '& .free-form-wrapper': { fontSize: '1.3rem' } }}>
        <Freeform blocks={parseBlocks(action.actionDescription)} />
      </Box>
      {action.resources?.length > 0 && (
        <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, paddingTop: '.5rem', paddingBottom: '.5rem' }}>Resources:</Typography>
      )}
      {action.resources?.map((r, j) => {
        return (
          <div key={j}>
            {r.fields.url?.href ? (
              <a href={r.fields.url.href} title={r.fields.title} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>
                {r.fields.title}
              </a>
            ) : (
              <span>{r.fields.title}</span>
            )}
          </div>
        );
      })}
    </Box>
  );
};

export default BehaviorAction;
